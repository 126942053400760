
<template>
  <v-container fluid fill-height class="pa-0 ma-0">
    <div class="login-backgroud-img"></div>
    <v-card v-if="!isComplete && !isError" id="login-card" class="elevation-1 login-card body-2">
      <v-toolbar color="blue-grey darken-4" dark flat height="80" :loading="loading">
        解除中...
      </v-toolbar>
    </v-card>
    <v-card v-else-if="isError" class="login-card">
      <dialog-error text="URLが無効です" to-top="true" />
    </v-card>
    <v-card v-else class="login-card">
      <dialog-complete text="ロックを解除しました" to-top="true" />
    </v-card>
  </v-container>
</template>


<script>
import axios from 'axios';
import DialogComplete from '@/components/DialogComplete.vue';
import DialogError from '@/components/DialogError.vue';

export default {
  components: {
    DialogComplete,
    DialogError,
  },
  name: 'Unlock',
  data: () => ({
    loading: false,
    isComplete: false,
    isError: false,
  }),
  created() {
    this.unlock();
  },
  methods: {
    //アカウントロックの解除
    async unlock() {
      this.loading = true;
      try {
        const response = await axios.put(`${process.env.VUE_APP_URL}unlock`, {
          sign: this.$route.query.sign,
          token: this.$route.query.token,
        }, {
          headers: {
            'X-Api-Key': process.env.VUE_APP_KEY,
            'Content-Type': 'application/json'
          }
        });

        if (response.data.results === 'complete') {
          this.isComplete = true;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
        }
      } catch (reason) {
        console.log(reason);
        this.loading = false;
        this.isError = true;
      }
    },
  },
};

</script>